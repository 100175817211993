import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import classes from './NotFound.module.css';

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.notFound}>
      <h1>{t('notFound.title')}</h1>
      <p>{t('notFound.message')}</p>
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => window.location.replace('/')}
      >
        {t('notFound.goBack')}
      </motion.button>
    </div>
  );
};

export default NotFound;
