import React, { useEffect } from 'react';

const GTMInitializer: React.FC = () => {
  useEffect(() => {
    const gtmId = process.env.REACT_APP_GOOGLE_TAG_ID;
    if (!gtmId) {
      console.error('REACT_APP_GOOGLE_TAG_ID is not defined');
      return;
    }

    // Prüfe, ob der GTM-Script bereits eingefügt wurde
    if (!document.querySelector(`script[src*="${gtmId}"]`)) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
      document.head.appendChild(script);
    }

    // Noscript-Element einfügen, falls noch nicht vorhanden
    if (!document.querySelector(`iframe[src*="${gtmId}"]`)) {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.insertBefore(noscript, document.body.firstChild);
    }
  }, []);

  return null;
};

export default GTMInitializer;