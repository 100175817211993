import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './colors.css';
import './i18n';
import './fontawesome';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import About from './components/About/About';
import Services from './components/Services/Services';
import Gallery from './components/Gallery/Gallery';
import FAQ from './components/FAQ/FAQ';
import Contact from './components/Contact/Contact';
import Direction from './components/Direction/Direction';
import Footer from './components/Footer/Footer';
import PrivacyPolicy from './components/Footer/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './components/Footer/TermsOfUse/TermsOfUse';
import CookiePolicy from './components/Footer/Cookie/CookiePolicy';
import CookiePopup from './components/Footer/Cookie/CookiePopup';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Cookies from 'js-cookie';
import ScrollHandler from './utils/scrollHandler';
import Imprint from './components/Footer/Imprint/Imprint';
import NotFound from './components/NotFound/NotFound';
import GTMInitializer from './components/GTMInitializer'; // Stelle sicher, dass der Pfad stimmt

const App: React.FC = () => {
  const [recaptchaKey, setRecaptchaKey] = useState<string | null>(null);
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(false);

  useEffect(() => {
    const accepted = Cookies.get('cookiesAccepted') === 'true';
    setCookiesAccepted(accepted);
    if (accepted) {
      setRecaptchaKey(process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? null);
    }
  }, []);

  return (
    <Router>
      <ScrollHandler />
      {/* GTMInitializer wird nur eingebunden, wenn cookiesAccepted true ist */}
      {cookiesAccepted && <GTMInitializer />}
      <div>
        {recaptchaKey ? (
          <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
            <Header />
            <main>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Home />
                      <About />
                      <Services />
                      <Gallery />
                      <Direction />
                      <Contact />
                      <FAQ />
                    </>
                  }
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/cookie-policy" element={<CookiePolicy />} />
                <Route path="/imprint" element={<Imprint />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
            <Footer />
          </GoogleReCaptchaProvider>
        ) : (
          <>
            <Header />
            <main>
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Home />
                      <About />
                      <Services />
                      <Gallery />
                      <Direction />
                      <Contact />
                      <FAQ />
                    </>
                  }
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/cookie-policy" element={<CookiePolicy />} />
                <Route path="/imprint" element={<Imprint />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
            <Footer />
          </>
        )}
        <CookiePopup />
      </div>
    </Router>
  );
};

export default App;